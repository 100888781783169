<template>
  <div class="ma-4">
    <admin-header></admin-header>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" lg="3">
            <v-select v-model="filterGroup" :items="groupFilterOptions" label="Major/Minor Filter" outlined hide-details dense></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select v-model="filterClass" :items="classFilterOptions" label="Class Level Filter" outlined hide-details dense multiple></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select v-model="filterEvent" :items="eventList" label="Event" item-text="name" item-value="_id" outlined hide-details dense clearable>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name  }}</v-list-item-title>
                  <v-list-item-subtitle>{{ stringFormatDate(item.date) }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.students || 0 }} Attended</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select v-model="eventAttended" :items="eventAttendOptions" label="Attended?" outlined hide-details dense>
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" :disabled="filteredStudents.length === 0" icon color="success" class="mt-0" style="height:36px;width:36px;transform:translateY(-4px)">
                      <v-icon>fal fa-file-excel</v-icon>
                    </v-btn>
                  </template>
                  <span>Export Current List to Excel</span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table :items="filteredStudents" :headers="headers">
      <template v-slot:no-data>
        <v-alert v-if="isLoading" type="info" outlined>Loading data...</v-alert>
        <v-alert v-else type="error" outlined>No students found matching the selected filter</v-alert>
      </template>
      <template v-slot:item.major="{ item }">{{ item.major.join('; ') }}</template>
      <template v-slot:item.minor="{ item }">{{ item.minor.join('; ') }}</template>
      <template v-slot:item.advisor="{ item }">{{ item.advisor }}</template>
      <template v-slot:item.courses="{ item }">
        <v-chip v-for="{ title, crn } in item.courses" :key="crn" label outlined small>{{ title }}</v-chip>
      </template>
      <template v-slot:item.events="{ item }">{{ item.events.length || 0 }}</template>
      <template v-slot:item.in="{ item }">{{ getEventField(item.events, 'in') }}</template>
      <template v-slot:item.out="{ item }">{{ getEventField(item.events, 'out') }}</template>
      <template v-slot:item.duration="{ item }">{{ getEventField(item.events, 'duration') }}</template>
    </v-data-table>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'
import { saveAs } from 'file-saver'

export default {
  components: {
    AdminHeader: () => import('@/components/student/music/admin/header')
  },
  setup (props, { root }) {
    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const terms = ref([])
    const termLabel = computed(() => terms.value.reduce((prev, { text, value }) => value === term.value ? text : prev, ''))

    const isLoading = ref(true)
    const students = ref([])

    const filterGroup = computed({
      get: () => root.$store.state.student.music.admin.stuGroup,
      set: (stuGroup) => root.$store.commit('student/setMusicAdmin', { stuGroup })
    })
    const filterClass = computed({
      get: () => root.$store.state.student.music.admin.classLevel,
      set: (classLevel) => root.$store.commit('student/setMusicAdmin', { classLevel })
    })
    const filterEvent = computed({
      get: () => root.$store.state.student.music.admin.event,
      set: (event) => root.$store.commit('student/setMusicAdmin', { event })
    })
    const eventAttended = computed({
      get: () => root.$store.state.student.music.admin.eventAttended,
      set: (eventAttended) => root.$store.commit('student/setMusicAdmin', { eventAttended })
    })
    const eventAttendOptions = ref([{ text: 'Attended', value: true }, { text: 'Did Not Attend', value: false }])
    const groupFilterOptions = ref([
      { text: 'Music Majors', value: 'major' },
      { text: 'Music Minors', value: 'minor' },
      { text: 'Non-Major/Minor', value: 'other' },
      { text: 'MUS111 Students', value: 'MUS111' },
      { text: 'MUS171 Students', value: 'MUS171' },
      { text: 'All Music Students', value: '' }
    ])
    const classFilterOptions = ref([
      { text: 'Freshmen', value: 'FR' },
      { text: 'Sophomores', value: 'SO' },
      { text: 'Juniors', value: 'JR' },
      { text: 'Seniors', value: 'SR' }
    ])
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId' },
        { text: 'Name', value: 'name' },
        { text: 'Class Level', value: 'classLevel' },
        { text: 'Major', value: 'major' },
        { text: 'Minor', value: 'minor' },
        { text: 'Advisor', value: 'advisor' },
        { text: 'Events Attended', value: 'events' },
        { text: 'MUS111/MUS171', value: 'courses', sortable: false }
      ]
      if (filterEvent.value !== '' && eventAttended.value) {
        arr.splice(6, 2, { text: 'Scan-In Time', value: 'in' }, { text: 'Scan-Out Time', value: 'out' }, { text: 'Time Attending Event', value: 'duration' })
      }
      return arr
    })
    const filteredStudents = computed(() => students.value.filter(({ major, minor, classLevel, classes, events }) => {
      switch (filterGroup.value) {
        case 'major':
          if (!(major.includes('MUS'))) return false
          break
        case 'minor':
          if (!(minor.includes('MUS'))) return false
          break
        case 'other':
          if (major.includes('MUS') || minor.includes('MUS')) return false
          break
        case 'MUS111':
        case 'MUS171':
          if (!classes.find(({ title }) => title.substring(0, 6) === filterGroup.value)) return false
          break
      }
      if (filterClass.value.length > 0 && !filterClass.value.includes(classLevel)) return false
      if (filterEvent.value != null && filterEvent.value !== '') {
        let rec = events.find(({ _id }) => _id === filterEvent.value)
        if (events.length > 0) {
          console.log(events)
          console.log(rec)
        }
        if ((eventAttended.value && !rec) || (!eventAttended.value && rec)) return false
      }
      return true
    }))

    async function loadStudents () {
      isLoading.value = true
      students.value = []

      const aggregate = [
        { $match: {
          term: term.value,
          $or: [
            { 'academics.major': 'MUS' },
            { 'academics.minor': 'MUS' },
            { 'schedule.title': { $regex: 'MUS[1-4]0[1-8]' } }
          ]
        } },
        { $project: {
          bannerId: 1,
          pidm: 1,
          name: 1,
          classLevel: '$academics.classLevel',
          major: '$academics.major',
          minor: '$academics.minor',
          advisor: '$academics.advisor',
          classes: '$schedule'
        } },
        { $lookup: {
          from: 'Student-MusicScanEvent',
          localField: 'bannerId',
          foreignField: 'students.bannerId',
          as: 'events',
          let: { bannerId: '$bannerId' },
          pipeline: [
            { $match: { term: term.value } },
            { $unwind: '$students' },
            { $match: { $expr: { $eq: ['$students.bannerId', '$$bannerId'] } } },
            { $project: { _id: 1, name: 1, in: '$students.in', out: '$students.out' } }
          ]
        } }
      ]
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      const regex = /MUS1[17]1/
      students.value = data.map((row) => {
        let temp = row.advisor.filter(({ primary, code }) => primary && code === 'MAJR')
        const courses = row.classes.filter(({ title }) => regex.test(title))
        if (temp.length > 0) {
          return { ...row, advisor: temp[0].name || temp[0], courses }
        }
        temp = row.advisor.filter(({ code }) => code === 'MAJR')
        if (temp.length > 0) {
          return { ...row, advisor: temp[0].name, courses }
        } else if ('advisor' in row && Array.isArray(row.advisor) && row.advisor.length > 0) {
          return { ...row, advisor: row.advisor[0].name, courses }
        }
        return { ...row, advisor: '**NO ADVISOR FOUND**', courses }
      })
      isLoading.value = false
    }
    const eventList = ref([])
    async function loadEvents () {
      const aggregate = [
        { $match: { term: term.value } },
        { $project: {
          _id: 1,
          date: 1,
          name: 1,
          students: { $cond: { if: { $isArray: '$students' }, then: { $size: '$students' }, else: '0' } }
        } },
        { $sort: { date: -1 } }
      ]
      const { data } = await root.$feathers.service('student/music/scan-event').find({ query: { aggregate } })
      eventList.value = data
    }

    onMounted(async () => {
      const aggregate = [
        { $match: {
          term: { $regex: '05$|60$' },
          $or: [
            { 'schedule.title': { $regex: 'MUS[1-4]0[1-8]' } },
            { 'academics.major': 'MUS' },
            { 'academics.minor': 'MUS' }
          ]
        } },
        { $group: {
          _id: '$term',
          majors: { $sum: { $cond: [{ $in: ['MUS', '$academics.major'] }, 1, 0] } },
          minors: { $sum: { $cond: [{ $in: ['MUS', '$academics.minor'] }, 1, 0] } },
          other: { $sum: { $cond: [
            {
              $and: [
                { $not: { $in: ['MUS', '$academics.major'] } },
                { $not: { $in: ['MUS', '$academics.minor'] } }
              ]
            }, 1, 0
          ] } }
        } },
        { $sort: { _id: -1 } }
      ]
      const { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      terms.value = data.map(({ _id: value }) => {
        const text = (value.substring(4) === '05' ? 'Spring' : 'Fall') + ' ' + value.substring(0, 4)
        return { text, value }
      })
      const rec = terms.value.find(({ value }) => value === term.value)
      if (!rec) term.value = terms.value[0].value
    })
    watch(term, () => {
      loadStudents()
      loadEvents()
    })
    // watch([term, filter, filterEvent, eventAttended], () => loadStudents())

    async function exportList () {
      const arr = [ ...headers.value ]
      arr.splice(4, 0, { text: 'Sports', value: 'sports', width: 20 })
      arr.splice(2, 0, { text: 'Email Address', value: 'email', width: 30 })
      const header = arr.filter((text) => text !== '').map(({ text: header, value: key, exportWidth: width }) => { return { header, align: (header === 'Events Attended' ? 'right' : 'left'), key, width } })
      let filename = 'Chapel Student Export.xlsx'
      const rows = students.value.map((row) => {
        const major = row.major ? row.major.join('; ') : ''
        const minor = row.minor ? row.minor.join('; ') : ''
        const courses = row.courses ? row.courses.join('; ') : ''
        return { ...row, courses, major, minor }
      })
      const data = await root.$feathers.service('export/xlsx').create({ filename, header, rows })
      saveAs(new Blob([data.buffer]), filename)
    }

    function getEventField (events, field) {
      const rec = events.find(({ _id }) => _id === filterEvent.value)
      if (field === 'duration') {
        const { in: timeIn, out: timeOut } = rec
        if (timeOut && timeIn) {
          const mins = (timeOut - timeIn) / 60000
          // const secs = ((timeOut - timeIn) % 60000) / 1000
          return mins + ' minutes'
        } else if (timeIn) {
          return '**No Scan Out**'
        } else {
          return '**No Scan In**'
        }
      } else if (field in rec) {
        return stringFormatDate(rec[field])
      }
      return ''
    }

    return {
      term,
      terms,
      termLabel,
      isLoading,
      students,
      headers,
      filterGroup,
      filterClass,
      filterEvent,
      eventAttended,
      eventAttendOptions,
      groupFilterOptions,
      classFilterOptions,
      filteredStudents,
      eventList,
      exportList,
      getEventField,
      stringFormatDate
    }
  }
}
</script>
